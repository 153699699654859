import axios from 'axios';
import {config} from "react-transition-group";
import {UploadResponse} from "../types";

const API_BASE_URL = 'https://ethera-app-demo.eu/v1';

const apiService = {
    uploadFiles: async (files: File[]): Promise<UploadResponse> => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        try {
            const response = await fetch(`${API_BASE_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {

                throw new Error('File upload failed');
            }

            const data = await response.json();


            return data;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    },



sendMessage: async (message: string, useDirectGpt: boolean): Promise<string> => {
        try {
            const response = await axios.post(`${API_BASE_URL}/chatbot`, { query: message, useDirectGpt }, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data.response;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    },

    getProcessingStatus: async (): Promise<{ totalPages: number, processedPages: number }> => {
        try {
            const response = await axios.get(`${API_BASE_URL}ж/processing-status` , {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error getting processing status:', error);
            throw error;
        }
    },
    getSessionId: async (): Promise<string> => {
        try {
            const response = await axios.get(`${API_BASE_URL}/session`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            return "asdasd";
        } catch (error) {
            console.error('Error getting session ID:', error);
            throw error;
        }
    },

    streamChatResponse: async (
        message: string,
        sessionId: string,
        useDirectGpt: boolean,
        onChunk: (chunk: string) => void
    ): Promise<void> => {
        try {
            const response = await fetch(`${API_BASE_URL}/chatbot-stream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
                body: JSON.stringify({ query: message, sessionId, useDirectGpt }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.message ||
                    errorData.details ||
                    (errorData.code ? `Error: ${errorData.code}` : null) ||
                    'An error occurred while processing your request';
                console.log(errorMessage);
                throw new Error(errorMessage);
            }
    
            const reader = response.body?.getReader();
            const decoder = new TextDecoder();
            
            let buffer = '';  // Keep track of the remaining buffer in case chunks are split
            
            while (true) {
                const { done, value } = await reader!.read();
                if (done) break;
    
                const chunk = decoder.decode(value, { stream: true });
                buffer += chunk;  // Append chunk to buffer
                
                const lines = buffer.split('\n\n');  // Split the buffer into individual SSE messages
    
                for (let i = 0; i < lines.length - 1; i++) {
                    const line = lines[i].trim();
                    if (line.startsWith('data:')) {
                        const data = line.slice(5).trim();
                        if (data === '[DONE]') {
                            return;  // Stream is finished
                        }
                        try {
                            const jsonData = JSON.parse(data);
                            onChunk(jsonData.content);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                }
    
                // Keep the last part of the buffer in case it’s incomplete
                buffer = lines[lines.length - 1];
            }
        } catch (error) {
            console.log(JSON.stringify(error));
            console.error('Error streaming chat response:', error);
            throw error;
        }
    },
    
};

export default apiService;