import React, { useState, useEffect } from 'react';
import styles from './PDFPreview.module.css';
import {useChatContext} from "../../contexts/ChatContext";

interface PDFPreviewProps {
    files: File[];
}

const PDFPreview: React.FC<PDFPreviewProps> = ({ files }) => {
    const { sessionId, useDirectGpt } = useChatContext();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    useEffect(() => {
        if (files.length > 0 && !selectedFile) {
            setSelectedFile(files[0]);
        }
    }, [files, selectedFile]);

    useEffect(() => {
        if (selectedFile) {
            const url = URL.createObjectURL(selectedFile);
            setFileUrl(url);

            // Clean up the object URL when the component unmounts or when selectedFile changes
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [selectedFile]);

    const predefinedQuestions = [
        "Цитирай ми дословно описаните имоти в документа и тяхното описание",
        "Цитирай номерата и датата на документа/документите",
        "Изведи резюмирано описаните имоти в документа/документите, заедно с техните характеристики ",
        "Отговорът да бъде като чист текст, да не е лист и да е без символи и стилизиран текст",
        "Под всяка информация ми цитирай, конретния документ и страница"
    ];


    const handleQuestionClick = async (question: string) => {
        try {
            const messageEvent = new CustomEvent('sendChatMessage', {
                detail: { message: question }
            });
            window.dispatchEvent(messageEvent);
        } catch (error) {
            console.error('Error sending question to chat:', error);
        }
    };

    return (
        <div className={styles.pdfPreviewContainer}>
            <div className={styles.listsContainer}>
                <div className={styles.fileList}>
                    <div className={styles.listHeader}>Файлове</div>
                    <div className={styles.scrollableArea}>
                        {files.map((file, index) => (
                            <div
                                key={index}
                                className={`${styles.fileItem} ${
                                    selectedFile === file ? styles.selected : ''
                                }`}
                                onClick={() => setSelectedFile(file)}
                            >
                                {file.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.fileList}>
                    <div className={styles.listHeader}>Въпроси</div>
                    <div className={styles.scrollableArea}>
                        {predefinedQuestions.map((question, index) => (
                            <div
                                key={index}
                                className={styles.fileItem}
                                onClick={() => handleQuestionClick(question)}
                            >
                                {question}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.previewArea}>
                {fileUrl ? (
                    <iframe
                        src={fileUrl}
                        title="PDF Preview"
                        width="100%"
                        height="500px"
                        style={{ border: 'none' }}
                        autoFocus={true}
                    />
                ) : (
                    <p>Select a file to preview</p>
                )}
            </div>
        </div>
    );
};

export default PDFPreview;
