import React, { useState, useRef } from 'react';
import styles from './FileUpload.module.css';
import apiService from '../../services/api.service';
import { ProcessingStatus } from '../ProcessingStatus/ProcessingStatus';
import { Notification } from "../Notification";
import { useChatContext } from "../../contexts/ChatContext";
import { TextExtractionStream } from "../TextExtractionStream";
import { DocumentScanner } from "../DocumentScanner";
import { UploadResponse } from '../../types';
import { PDFDocument } from 'pdf-lib';

interface FileUploadProps {
    onUploadSuccess: (files: File[], response: UploadResponse) => void;
    onUploadError: (error: string) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({ onUploadSuccess, onUploadError }) => {
    // State management
    const [files, setFiles] = useState<File[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [processingStatus, setProcessingStatus] = useState<{ totalPages: number, processedPages: number } | null>(null);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [notification, setNotification] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

    // Refs
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { setUseDirectGpt } = useChatContext();

    // File validation
    const validateFiles = async (filesToValidate: File[]): Promise<boolean> => {
        const maxTotalSize = 24 * 1024 * 1024; // 32MB
        const maxTotalPages = 100;
        const validTypes = ['application/pdf'];
        let totalSize = 0;
        let totalPages = 0;

        for (const file of filesToValidate) {
            if (!validTypes.includes(file.type)) {
                onUploadError('Моля, качете само PDF файлове.');
                return false;
            }

            totalSize += file.size;
            if (totalSize > maxTotalSize) {
                onUploadError('Общият размер на файловете надвишава 24MB');
                return false;
            }

            try {
                const numPages = await getPdfPageCount(file);
                totalPages += numPages;

                if (totalPages > maxTotalPages) {
                    onUploadError('Общият брой страници надвишава 100');
                    return false;
                }
            } catch (error) {
                console.error(`Error reading file ${file.name}:`, error);
                onUploadError(`Неуспешно четене на файла ${file.name}.`);
                return false;
            }
        }

        return true;
    };

    async function getPdfPageCount(file: File): Promise<number> {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer, {ignoreEncryption: true});
        const numPages = pdfDoc.getPageCount();
        return numPages;
    }

    // Event handlers
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            let areFilesValid = await validateFiles(newFiles)

            if (areFilesValid) {
                setFiles(newFiles);
            }
        }
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.contains(event.relatedTarget as Node)) return;
        setIsDragging(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);

        const droppedFiles = Array.from(event.dataTransfer.files);
        let areFilesValid = await validateFiles(droppedFiles)

        if (areFilesValid) {
            setFiles(droppedFiles);
        }
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            onUploadError('Моля, изберете файлове за качване.');
            return;
        }

        setIsUploading(true);
        setIsProcessing(true);
        try {
            const response = await apiService.uploadFiles(files);
            setUploadComplete(true);
            onUploadSuccess(files, response);
            setNotification({
                message: 'Файловете са качени успешно!',
                type: 'success'
            });
        } catch (error) {
            console.error('Upload error:', error);
            onUploadError('Възникна грешка при качването на файловете. Моля, опитайте отново.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleBrowseClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className={styles.fileUploadContainer}>
            {!isProcessing ? (
                <>
                    <div
                        className={`${styles.fileUploadArea} ${isDragging ? styles.dragging : ''}`}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".pdf"
                            multiple
                            className={styles.fileInput}
                        />
                        <p>Плъзнете и пуснете файлове тук или</p>
                        <button
                            className={styles.uploadButton}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            Изберете файлове
                        </button>
                    </div>

                    {files.length > 0 && (
                        <div className={styles.fileListContainer}>
                            <h3>Избрани файлове:</h3>
                            <div className={styles.fileList}>
                                {files.map((file, index) => (
                                    <div key={index} className={styles.fileName}>
                                        {file.name} ({(file.size / (1024 * 1024)).toFixed(2)} MB)
                                    </div>
                                ))}
                            </div>
                            <button
                                className={`${styles.uploadButton} ${isUploading ? styles.loading : ''}`}
                                onClick={handleUpload}
                                disabled={isUploading}
                            >
                                {isUploading ? (
                                    <>
                                        <span className={styles.spinner}></span>
                                        Качване...
                                    </>
                                ) : (
                                    'Качете файловете'
                                )}
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.processingContainer}>
                    <h2>Обработка на документите</h2>
                    <div className={styles.contentWrapper}>
                        <div className={styles.scannerWrapper}>
                            <DocumentScanner />
                        </div>
                        <div className={styles.streamWrapper}>
                            {(isUploading || uploadComplete) && processingStatus && (
                                <ProcessingStatus
                                    totalFiles={processingStatus.totalPages}
                                    processedFiles={processingStatus.processedPages}
                                    uploadComplete={uploadComplete}
                                />
                            )}
                            <TextExtractionStream
                                isProcessing={isProcessing}
                            />
                        </div>
                        <div className={styles.scannerWrapper}>
                            <DocumentScanner />
                        </div>
                    </div>
                </div>
            )}

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}
        </div>
    );
};

export default FileUpload;