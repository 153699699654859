import React, { useState, useEffect } from 'react';
import styles from './TextExtractionStream.module.css';

interface TextExtractionStreamProps {
    isProcessing: boolean;
}

const loadingMessages = [
    "Инициализиране на системата...",
    "Анализиране на документите...",
    "Извличане на съдържанието...",
    "Обработка на текст и изображения...",
    "Оптимизиране на извлечените данни...",
    "Подготовка на документите за анализ...",
    "Прилагане на AI алгоритми...",
    "Проверка на качеството на извлечените данни...",
    "Финализиране на обработката..."
];

export const TextExtractionStream: React.FC<TextExtractionStreamProps> = ({ isProcessing }) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [dots, setDots] = useState('');

    // Effect for changing messages
    useEffect(() => {
        if (!isProcessing) return;

        const messageInterval = setInterval(() => {
            setCurrentMessageIndex((prevIndex) =>
                prevIndex === loadingMessages.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Change message every 3 seconds

        return () => clearInterval(messageInterval);
    }, [isProcessing]);

    // Effect for animated dots
    useEffect(() => {
        if (!isProcessing) return;

        const dotsInterval = setInterval(() => {
            setDots(prev => {
                if (prev === '...') return '';
                return prev + '.';
            });
        }, 500); // Update dots every 500ms

        return () => clearInterval(dotsInterval);
    }, [isProcessing]);

    if (!isProcessing) return null;

    return (
        <div className={styles.streamWrapper}>
            <div className={styles.streamContainer}>
                <div className={styles.loadingMessage}>
                    <div className={styles.messageText}>
                        {loadingMessages[currentMessageIndex]}{dots}
                    </div>
                    <div className={styles.progressAnimation}>
                        <div className={styles.progressBar}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextExtractionStream;